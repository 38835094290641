import PropTypes from "prop-types";

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { NavBasicDesktop } from 'src/components/nav-section/nav-basic';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// API
import { useGetPageBySlug } from 'src/api/web';
import { useLocales } from 'src/locales';
// routes
import { paths } from 'src/routes/paths';
import { useParams, usePathname, useRouter } from 'src/routes/hook';

// components
import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';
//
import { HEADER } from '../config-layout';
import NavMobile from './nav/mobile';
//
import { HeaderShadow, LanguagePopover } from '../_common';

// ----------------------------------------------------------------------

export default function Header({ siteSetting }) {
  const theme = useTheme();
  const pathname = usePathname();
  const isHome = pathname === '/';

  const params = useParams();
  const { t, currentLang } = useLocales();

  const mdUp = useResponsive('up', 'md');

  const [fixed, setFixed] = useState(false);

  const { page: mainNavigationOne } = useGetPageBySlug(currentLang.value, 'navigation?type=main1');
  const { page: mainNavigationTwo } = useGetPageBySlug(currentLang.value, 'navigation?type=main2');

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  const location = useLocation()

  const functionFixed = () => {
    if (window.scrollY > 0) {
      setFixed(true);
    }
    else {
      setFixed(false);
    }

  }
  useEffect(() => {
    window.addEventListener("scroll", functionFixed);
    // if (location.pathname === `/properties/${params.uuid}`) {
    //   setFixed(false);
    // }
  }, [location.pathname, params])

  return (
    <>
      {/* {fixed && location.pathname === `/properties/${params.uuid}` ? null : */}
      <AppBar>
        <Toolbar
          disableGutters
          sx={{
            backgroundColor: {
              xs: "white",
              sm: "transparent",
            },
            ...(!isHome && {
              color: "black",
            }),
            ...(isHome && {
              color: "white",
            }),
            height: {
              xs: HEADER.H_MOBILE,
              md: HEADER.H_DESKTOP,
            },
            transition: theme.transitions.create(['height'], {
              easing: theme.transitions.easing.easeInOut,
              duration: theme.transitions.duration.shorter,
            }),
            ...(offsetTop && {
              backgroundColor: "white",
              color: "black",
              height: {
                md: HEADER.H_DESKTOP_OFFSET,
              },
            }),
          }}
        >
          <Container maxWidth="xl">
            {mdUp &&
              <Grid container spacing={3} sx={{ mt: -1 }}>
                <Grid sm={5}>
                  <NavBasicDesktop
                    data={mainNavigationOne}
                    slotProps={{
                      rootItem: {
                        fontFamily: theme.typography.fontSecondaryFamily,
                      },
                    }}
                  />
                </Grid>
                <Grid sm={2} sx={{ textAlign: "center" }}>
                  <Logo fileURL={siteSetting.logo} sx={{ height: "40px" }} />
                </Grid>
                <Grid sm={5} sx={{ textAlign: "right" }}>
                  <Stack
                    direction="row"
                    justifyContent="end"
                    spacing={2}
                  >
                    <NavBasicDesktop
                      data={mainNavigationTwo}
                      slotProps={{
                        rootItem: {
                          fontFamily: theme.typography.fontSecondaryFamily,
                        },
                      }}
                    />
                    <Link href={paths.login} sx={{ mt: 0.8 }}>
                      <Iconify
                        icon="mdi:user"
                        sx={{
                          width: 26,
                          height: 26,
                          ...(!isHome && {
                            color: 'black',
                          }),
                          ...(isHome && {
                            color: offsetTop ? 'black' : 'white',
                          }),
                        }}
                      />
                    </Link>
                    <LanguagePopover />
                  </Stack>
                </Grid>
              </Grid>
            }

            {!mdUp && (
              <Container sx={{ display: "flex", pl: 0 }}>
                <NavMobile data={mainNavigationOne} data2={mainNavigationTwo} />
                <Box flexGrow={1} />
                <Logo fileURL={siteSetting?.logo} sx={{ height: "40px" }} />
                <Box flexGrow={1} />
                <Link href={paths.login} sx={{ mt: 0.8 }}>
                  <Iconify icon="mdi:user" color="black" sx={{ width: 26, height: 26 }} />
                </Link>
                <LanguagePopover />
              </Container>
            )
            }
          </Container>
        </Toolbar>

        {offsetTop && <HeaderShadow />}
      </AppBar>
    </>

  );
}

Header.propTypes = {
  siteSetting: PropTypes.object,
};