import PropTypes from 'prop-types';
// @mui
import Container from '@mui/material/Container';
// routes
import { useLocales } from 'src/locales';
import { paths } from 'src/routes/paths';
// api
import { useGetRow } from 'src/api/api';
// components

import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import MerchantTypeForm from '../form';
// ----------------------------------------------------------------------

export default function MerchantTypeEditView({ currentRow }) {
    const { t,currentLang } = useLocales();

    return (
        <Container maxWidth='xxl'>
            <CustomBreadcrumbs
                heading={ t('merchantTypes') }
                links={[
                    {
                        name: t('dashboard'),
                        href: paths.dashboard.root,
                    },
                    {
                        name: t('settings'),
                        href: paths.dashboard.settings.wallets.root,
                    },
                    {
                      name: t('wallets'),
                      href: paths.dashboard.settings.wallets.root,
                    },
                    {
                        name: t('merchantTypes'),
                        href: paths.dashboard.settings.wallets.types.root,
                    },
                    { name: currentRow?.name },
                ]}
                sx={{
                    mb: { xs: 3, md: 5 },
                }}
            />

            <MerchantTypeForm currentRow={currentRow} />
        </Container>
    );
}

MerchantTypeEditView.propTypes = {
  currentRow: PropTypes.object,
};
