import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import { alpha } from '@mui/material/styles';

import Iconify from 'src/components/iconify';
import { Link } from '@mui/material';

// ----------------------------------------------------------------------
export default function Socials({ profile }) {
    return (
        <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{
                mb: { xs: 5, md: 0 },
              }}
            >
                {
                    profile?.social_facebook === null ? '' : 
                    <Link href={ profile?.social_facebook } target="_blank">
                        <IconButton
                            sx={{
                            '&:hover': {
                                bgcolor: alpha("#1877F2", 0.08),
                            },
                            }}
                        >
                            <Iconify color="#1877F2" icon="eva:facebook-fill" />
                        </IconButton>
                    </Link>
                } 
                {
                    profile?.social_instagram === null ? '' : 
                    <Link href={ profile?.social_instagram } target="_blank">
                        <IconButton
                            sx={{
                            '&:hover': {
                                bgcolor: alpha("#E02D69", 0.08),
                            },
                            }}
                        >
                            <Iconify color="#E02D69" icon="ant-design:instagram-filled" />
                        </IconButton>
                    </Link>
                } 
                {
                    profile?.social_linkedin === null ? '' : 
                    <Link href={ profile?.social_linkedin } target="_blank">
                        <IconButton
                            sx={{
                            '&:hover': {
                                bgcolor: alpha("#007EBB", 0.08),
                            },
                            }}
                        >
                            <Iconify color="#007EBB" icon="eva:linkedin-fill" />
                        </IconButton>
                    </Link>
                } 
                {
                    profile?.social_twitter === null ? '' : 
                    <Link href={ profile?.social_twitter } target="_blank">
                        <IconButton
                            sx={{
                            '&:hover': {
                                bgcolor: alpha("#00AAEC", 0.08),
                            },
                            }}
                        >
                            <Iconify color="#00AAEC" icon="eva:twitter-fill" />
                        </IconButton>
                    </Link>
                } 
                {
                    profile?.social_telegram === null ? '' : 
                    <Link href={ profile?.social_telegram } target="_blank">
                        <IconButton
                            sx={{
                            '&:hover': {
                                bgcolor: alpha("#00AAEC", 0.08),
                            },
                            }}
                        >
                            <Iconify color="#00AAEC" icon="la:telegram" />
                        </IconButton>
                    </Link>
                } 
                {
                    profile?.social_youtube === null ? '' : 
                    <Link href={ profile?.social_youtube } target="_blank">
                        <IconButton
                            sx={{
                            '&:hover': {
                                bgcolor: alpha("#FF0000", 0.08),
                            },
                            }}
                        >
                            <Iconify color="#FF0000" icon="mdi:youtube" />
                        </IconButton>
                    </Link>
                } 
                {
                    profile?.social_threads === null ? '' : 
                    <Link href={ profile?.social_threads } target="_blank">
                        <IconButton
                            sx={{
                            '&:hover': {
                                bgcolor: alpha("#000000", 0.08),
                            },
                            }}
                        >
                            <Iconify color="#000000" icon="ri:threads-fill" />
                        </IconButton>
                    </Link>
                } 
                {
                    profile?.social_tiktok === null ? '' : 
                    <Link href={ profile?.social_tiktok } target="_blank">
                        <IconButton
                            sx={{
                            '&:hover': {
                                bgcolor: alpha("#000000", 0.08),
                            },
                            }}
                        >
                            <Iconify color="#000000" icon="ic:baseline-tiktok" />
                        </IconButton>
                    </Link>
                }
        </Stack>
    )
}

Socials.propTypes = {
    profile: PropTypes.object,
};
