import useSWR from 'swr';
import { useMemo } from 'react';
// utils
import axiosInstance, { fetcher } from 'src/utils/axios';

export function useGetWebHome(locale) {
  const URL = `/api/${locale}/web`;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      blockHomeSearch: data?.blockHomeSearch || [],
      homeWelcome: data?.homeWelcome || [],
      blockLatestProperties: data?.blockLatestProperties || [],
      featureProperties: data?.featureProperties || [],
      propertyTypeOne: data?.propertyTypeOne || [],
      propertyTypeTwo: data?.propertyTypeTwo || [],
      latestProperties: data?.latestProperties || [],
      services: data?.services || [],
      sliders: data?.sliders || [],
      rowsLoading: isLoading,
      rowsError: error,
      rowsValidating: isValidating,
      // rowsEmpty: !isLoading && !data?.data.length,
    }),
    [data?.blockHomeSearch, data?.homeWelcome, data?.blockLatestProperties, data?.featureProperties, data?.propertyTypeOne, data?.propertyTypeTwo, data?.latestProperties, data?.services, data?.sliders, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useWebGetRows(locale, model) {
  const URL = `/api/${locale}/web/${model}`;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      rows: data?.data || [],
      rowsLoading: isLoading,
      rowsError: error,
      rowsValidating: isValidating,
      // rowsEmpty: !isLoading && !data?.data.length,
    }),
    [data?.data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// Get All Data with Pagination
export function useWebGetRowsPagination(locale, model, queryString = '') {
  const URL = `/api/${locale}/web/${model}?${queryString}`;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      rows: data?.data || [],
      pagination: data?.pagination || 0,
      rowsLoading: isLoading,
      rowsError: error,
      rowsValidating: isValidating,
      rowsEmpty: !isLoading && !data?.data.length,
    }),
    [data?.data, error, isLoading, isValidating, data?.pagination]
  );

  return memoizedValue;
}

// Show Signle Data
export function useWebGetRow(locale, model, id = null) {
  const URL = `/api/${locale}/web/${model}${id === null ? '' : `/${id}`}`;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  const memoizedValue = useMemo(
    () => ({
      row: data?.data || [],
      rowLoading: isLoading,
      rowError: error,
      rowValidating: isValidating,
      rowEmpty: !isLoading && !data?.data.length,
      status: data?.status || 0,
    }),
    [data?.data, data?.status, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// Get Page by Slug
export function useGetPageBySlug(locale, slug) {
  const URL = `api/${locale}/web/${slug}`;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  const memoizedValue = useMemo(
    () => ({
      page: data?.data ?? [],
      pageLoading: isLoading,
      pageError: error,
      pageValidating: isValidating
    }),
    [data?.data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function usePropertySettings(locale, model = null, parentId = null, id = 'id', name = 'name') {
  const URL = `/api/${locale}/web/properties/settings/${model}/${parentId}/${id}/${name}`;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      purposes: data?.purposes || [],
      titles: data?.titles || [],
      types: data?.types || [],
      rowsLoading: isLoading,
      rowsError: error,
      rowsValidating: isValidating,
      // rowsEmpty: !isLoading && !data?.data.length,
    }),
    [data?.purposes, data?.types, data?.titles, error, isLoading, isValidating]
  );

  return memoizedValue;
}
export async function DeleteImage(locale, puuid, uuid) {

  const URL = `/api/${locale}/properties/property/${puuid}/${uuid}`;
  const res = await axiosInstance.delete(URL);
  return res;
}