import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocales } from 'src/locales';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import FormProvider, {
  RHFTextField
} from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import { saveFormData } from 'src/api/api';

// ----------------------------------------------------------------------

export default function MerchantTypeForm({ currentRow }) {
  const router = useRouter();
  const { t, currentLang } = useLocales();

  const loadingSend = useBoolean();

  const { enqueueSnackbar } = useSnackbar();

  const NewPurposeSchema = Yup.object().shape({
    name: Yup.string().required('សូមបញ្ចូលឈ្មោះខ្មែរ'),
    name_en: Yup.string().required('please input name'),
    ordered: Yup.number().required('required order'),
  });

  const defaultValues = useMemo(
    () => ({
      _method: currentRow ? 'PUT' : 'POST',
      name: currentRow?.name_km || '',
      name_en: currentRow?.name_en || '',
      description: currentRow?.description_km || '',
      description_en: currentRow?.description_en || '',
      icon_web: currentRow?.icon_web || '',
      ordered: currentRow?.ordered || 9,
    }),
    [currentRow]
  );

  const methods = useForm({
    resolver: yupResolver(NewPurposeSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = methods;

  useEffect(() => {
    if (currentRow)
      reset(defaultValues);
  }, [currentRow, defaultValues, reset]);

  const handleCreateAndSend = handleSubmit(async (data) => {
    loadingSend.onTrue();
    try {
      // send to server
      const res = await saveFormData(currentLang.value, 'merchant-types', {
        _method: currentRow ? 'PUT' : 'POST',
        name: data.name,
        name_en: data.name_en,
        description: data.description,
        description_en: data.description_en,
        icon_web: data.icon_web,
        ordered: data.ordered,
      }, currentRow?.id);
      // after response from server
      if (res.status === 200) {
        enqueueSnackbar(res.data);
        loadingSend.onFalse();
        router.push(paths.dashboard.settings.wallets.types.root);
      }
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      if (error?.errors?.name) { setError('name', { type: 'serve', message: error?.errors?.name[0] }); }
      if (error?.errors?.name_en) { setError('name_en', { type: 'serve', message: error?.errors?.name_en[0] }); }
      if (error?.errors?.ordered) { setError('ordered', { type: 'serve', message: error?.errors?.ordered[0] }); }
      loadingSend.onFalse();
    }
  });

  return (
    <FormProvider methods={methods}>
      <Box>
        <Grid container spacing={3}>
          <Grid sm={6}>
            <Paper
              variant="outlined"
              sx={{
                p: 3,
                borderRadius: 1.5,
                borderStyle: 'dashed',
                bgcolor: (theme) => alpha(theme.palette.grey[100], 0.9),
              }}
            >
              <RHFTextField name="name" label="ឈ្មោះ *" sx={{ mb: 3 }}
                error={errors?.name}
                helperText={errors?.name?.message}
              />
              <RHFTextField
                name="description"
                rows={4}
                fullWidth
                multiline
                label="ពិពណ៌នា"
              />
            </Paper>
          </Grid>
          <Grid sm={6}>
            <Paper
              variant="outlined"
              sx={{
                p: 3,
                borderRadius: 1.5,
                borderStyle: 'dashed',
                bgcolor: (theme) => alpha(theme.palette.grey[100], 0.9),
              }}
            >
              <RHFTextField name="name_en" label="Name *" sx={{ mb: 3 }}
                error={errors?.name_en}
                helperText={errors?.name_en?.message}
              />
              <RHFTextField
                name="description_en"
                rows={4}
                fullWidth
                multiline
                label="Description"
              />
            </Paper>
          </Grid>
          <Grid sm={12}>
            <Paper
              variant="outlined"
              sx={{
                p: 3,
                borderRadius: 1.5,
                borderStyle: 'dashed',
                bgcolor: (theme) => alpha(theme.palette.grey[100], 0.9),
              }}
            >
              <Grid container spacing={3}>
                <Grid sm={3}>
                  <RHFTextField
                    name="icon_web"
                    label={t('webIcon')}
                  />
                </Grid>
                <Grid sm={3}>
                  <RHFTextField
                    name="ordered"
                    label={`${t('ordered')}`}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    error={errors?.ordered}
                    helperText={errors?.ordered?.message}
                  />
                </Grid>
                <Grid sm={6}>
                  <Stack justifyContent="flex-end" direction="row">
                    <LoadingButton
                      size="large"
                      variant="contained"
                      loading={loadingSend.value && isSubmitting}
                      onClick={handleCreateAndSend}

                      startIcon={<Iconify icon="material-symbols:save-outline" />}
                    >
                      {currentRow ? t('update') : t('save')} & {t('send')}
                    </LoadingButton>
                  </Stack>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>

    </FormProvider>
  );
}

MerchantTypeForm.propTypes = {
  currentRow: PropTypes.object,
};
