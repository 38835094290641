import { useCallback } from 'react';
// @mui
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
// routes
import { useLocales } from 'src/locales';
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
import { RouterLink } from 'src/routes/components';

// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
} from 'src/components/table';
import { useGetRows } from 'src/api/api';

import LoanTypeTableRow from "../table-row";
// ----------------------------------------------------------------------

export default function MerchantTypeListView() {
  const {t,currentLang} = useLocales();
  const router = useRouter();

  const table = useTable({ defaultOrderBy: 'ordered' });

  const { rows } = useGetRows(currentLang.value,'merchant-types');

  const denseHeight = table.dense ? 56 : 76;

  const notFound = !rows.length;

  const TABLE_HEAD = [
    { id: 'name_both', label: t('name') },
    { id: 'merchants', label: t('merchants'), width: '15%' },
    { id: 'ordered', label: t('ordered'), width: '15%' },
    { id: '', width: '100px' },
  ];

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.settings.wallets.types.edit(id));
    },
    [router]
  );
  
  return (
      <Container maxWidth='xxl'>
        <CustomBreadcrumbs
          heading= { t('merchantTypes') }
          links={[
            {
              name: t('dashboard'),
              href: paths.dashboard.root,
            },
            {
              name: t('settings'),
              href: paths.dashboard.settings.wallets.root,
            },
            {
              name: t('merchants'),
              href: paths.dashboard.settings.wallets.root,
            },
            {
              name: t('merchantTypes'),
            },
          ]}
          action={
            <Button
              component={RouterLink}
              href={paths.dashboard.settings.wallets.types.new}
              variant="contained"
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              {t('addNew')}
            </Button>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Card>

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  headLabel={TABLE_HEAD}
                />

                <TableBody>
                  {rows
                    .slice(
                      table.page * table.rowsPerPage,
                      table.page * table.rowsPerPage + table.rowsPerPage
                    )
                    .map((row) => (
                      <LoanTypeTableRow
                        key={row.id}
                        row={row}
                        onEditRow={() => handleEditRow(row.id)}
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(table.page, table.rowsPerPage, rows.length)}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>
        </Card>
      </Container>
  );
}