import PropTypes from "prop-types";
// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// _mock

// components
import Logo from 'src/components/logo';
import Markdown from "src/components/markdown";
import IconButton from "@mui/material/IconButton";
import Iconify from "src/components/iconify";
import Socials from 'src/components/socials/socials';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'EYES',
    children: [
      { name: 'About us', href: paths.about },
      { name: 'Contact us', href: paths.contact },
      { name: 'FAQs', href: paths.faqs },
    ],
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Condition', href: '#' },
      { name: 'Privacy Policy', href: '#' },
    ],
  },
  {
    headline: 'Contact',
    children: [{ name: 'info@eyesrealestate.com', href: '#' }],
  },
];

// ----------------------------------------------------------------------

export default function Footer({ siteSetting }) {
  const mainFooter = (
    <Box
      component="footer"
      sx={{
        mt: 6,
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Divider />

      <Container
      maxWidth="xl"
        sx={{
          pt: 6,
          pb: 2,
          textAlign: { xs: 'center', md: 'unset' },
        }}
      >
        <Logo sx={{ mb: 3, maxWidth: "250px" }} fileURL={siteSetting.footer_logo} />

        <Grid container sx={{ mb: 2}}>
          <Grid xs={12} md={4}>
            <Typography
              variant="body2"
              sx={{
                mx: { xs: 'auto', md: 'unset' },
              }}
            >
              { siteSetting?.footer_first }
            </Typography>

            <Socials profile={ siteSetting } />
          </Grid>

          <Grid xs={12} md={5} mdOffset={3}>
            <Box>
              <Grid container spacing={2}>
                <Grid xs={12} sm={6}>
                  <Markdown children={ siteSetting?.footer_second } />
                </Grid>
                <Grid xs={12} sm={6}>
                  <Markdown children={ siteSetting?.footer_third } />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Divider />
        <Typography variant="body2" sx={{ mt: 2 }}>
          <Markdown children={ siteSetting?.copyrights } />
        </Typography>
      </Container>
    </Box>
  );

  return mainFooter;
}

Footer.propTypes = {
  siteSetting: PropTypes.object,
};