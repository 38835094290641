import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import ProfileLayout from 'src/layouts/profile';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/me/me'));

const RequestedListPage = lazy(() => import('src/pages/me/requested/list'));
const RequestedCreatePage = lazy(() => import('src/pages/me/requested/create'));
const RequestedEditPage = lazy(() => import('src/pages/me/requested/edit'));
const RequestedShowPage = lazy(() => import('src/pages/me/requested/show'));

const CustomerListPage = lazy(() => import('src/pages/me/customers/list'));
const CustomerCreatePage = lazy(() => import('src/pages/me/customers/create'));
const CustomerEditPage = lazy(() => import('src/pages/me/customers/edit'));
const CustomerShowPage = lazy(() => import('src/pages/me/customers/show'));

const LoanListPage = lazy(() => import('src/pages/me/loans/list'));
const LoanCreatePage = lazy(() => import('src/pages/me/loans/create'));
const LoanEditPage = lazy(() => import('src/pages/me/loans/edit'));
const LoanShowPage = lazy(() => import('src/pages/me/loans/show'));

const PropertyListPage = lazy(() => import('src/pages/me/property/list'));
const PropertyCreatePage = lazy(() => import('src/pages/me/property/create'));
const PropertyEditPage = lazy(() => import('src/pages/me/property/edit'));


const WalletListPage = lazy(() => import('src/pages/me/wallet/list'));
const ProfileListPage = lazy(() => import('src/pages/me/profile/list'));
// ----------------------------------------------------------------------
const BankBlogListPage = lazy(() => import('src/pages/me/blogs/list'));
const BankBlogCreatePage = lazy(() => import('src/pages/me/blogs/new'));
const BankBlogEditPage = lazy(() => import('src/pages/me/blogs/edit'));
const BankStaffListPage = lazy(() => import('src/pages/me/staff/list'));
const BankStaffEditPage = lazy(() => import('src/pages/me/staff/edit'));
const BankStaffShowPage = lazy(() => import('src/pages/me/staff/show'));

export const meRoutes = [
  {
    path: 'me',
    element: (
      <AuthGuard>
        <ProfileLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </ProfileLayout>
      </AuthGuard>
    ),
    children: [
      { element: <ProfileListPage />, index: true },
      { path: 'blogs',
        children: [
          { element: <BankBlogListPage />, index: true },
          { path: 'create', element: <BankBlogCreatePage /> },
          { path: ':id/edit', element: <BankBlogEditPage /> },
        ],
      },
      { path: 'customers',
        children: [
          { element: <CustomerListPage />, index: true },
          { path: 'create', element: <CustomerCreatePage /> },
          { path: ':id/edit', element: <CustomerEditPage /> },
          { path: ':id', element: <CustomerShowPage /> },
        ],
      },
      { path: 'loans',
        children: [
          { element: <LoanListPage />, index: true },
          { path: 'create', element: <LoanCreatePage /> },
          { path: ':id/edit', element: <LoanEditPage /> },
          { path: ':id', element: <LoanShowPage /> },
        ],
      },
      { path: 'properties',
        children: [
          { element: <PropertyListPage />, index: true },
          { path: 'create', element: <PropertyCreatePage /> },
          { path: ':id/edit', element: <PropertyEditPage /> },
        ],
      },
      { path: 'requested',
        children: [
          { element: <RequestedListPage />, index: true },
          { path: 'create', element: <RequestedCreatePage /> },
          { path: ':id/edit', element: <RequestedEditPage /> },
          { path: ':id', element: <RequestedShowPage /> },
        ],
      },
      { path: 'staff',
        children: [
          { element: <BankStaffListPage />, index: true },
          { path: ':id/edit', element: <BankStaffEditPage /> },
          { path: ':id', element: <BankStaffShowPage /> },
        ],
      },
      { path: 'wallet', element: <WalletListPage /> },
      { path: 'profile', element: <ProfileListPage /> },
    ],
  },
];
