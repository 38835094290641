const ROOTS = {
  DASHBOARD: '/dashboard',
  DASHBOARD_LOCATION: '/dashboard/locations',
  DASHBOARD_PEOPLE: '/dashboard/people',
  DASHBOARD_PROPERTIES: '/dashboard/properties',
  DASHBOARD_REQUESTED: '/dashboard/requested',
  DASHBOARD_SETTINGS: '/dashboard/settings',
  DASHBOARD_WEBSITE: '/dashboard/website',
  ME: '/me',
};

// ----------------------------------------------------------------------

export const paths = {
  comingSoon: '/coming-soon',
  properties: {
    root: '/properties',
    show: (uuid) => `/properties/${uuid}`,
  },
  maps: '/maps',
  about: '/about-us',
  contact: '/contact-us',
  page403: '/403',
  page404: '/404',
  page500: '/500',

  login: `/login`,
  register: `/register`,


  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    // Reqeusted
    requested: {
      root: `${ROOTS.DASHBOARD_REQUESTED}`,
      create: `${ROOTS.DASHBOARD_REQUESTED}/create`,
      edit: (id) => `${ROOTS.DASHBOARD_REQUESTED}/${id}/edit`,
      show: (id) => `${ROOTS.DASHBOARD_REQUESTED}/${id}`,
    },
    // Properties
    properties: {
      root: `${ROOTS.DASHBOARD_PROPERTIES}`,
      create: `${ROOTS.DASHBOARD_PROPERTIES}/create`,
      edit: (id) => `${ROOTS.DASHBOARD_PROPERTIES}/${id}/edit`,
      show: (id) => `${ROOTS.DASHBOARD_PROPERTIES}/${id}`,
    },
    loans: {
      root: `${ROOTS.DASHBOARD}/loans`,
      create: `${ROOTS.DASHBOARD}/loans/create`,
      edit: (id) => `${ROOTS.DASHBOARD}/loans/${id}/edit`,
      show: (id) => `${ROOTS.DASHBOARD}/loans/${id}`,
    },
    people: {
      root: `${ROOTS.DASHBOARD_PEOPLE}`,
      create: `${ROOTS.DASHBOARD_PEOPLE}/create`,
      edit: (id) => `${ROOTS.DASHBOARD_PEOPLE}/${id}/edit`,
    },
    bankBranches: {
      root: `${ROOTS.DASHBOARD}/bank-branches`,
      new: `${ROOTS.DASHBOARD}/bank-branches/create`,
      show: (id) => `${ROOTS.DASHBOARD}/bank-branches/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/bank-branches/${id}/edit`,
    },
    locations: {
      root: `${ROOTS.DASHBOARD_LOCATION}`,
      new: `${ROOTS.DASHBOARD_LOCATION}/new`,
      edit: (id) => `${ROOTS.DASHBOARD_LOCATION}/${id}/edit`,
      districts: {
        root: `${ROOTS.DASHBOARD_LOCATION}/districts`,
        new: `${ROOTS.DASHBOARD_LOCATION}/districts/new`,
        edit: (id) => `${ROOTS.DASHBOARD_LOCATION}/districts/${id}/edit`,
      },
      communes: {
        root: `${ROOTS.DASHBOARD_LOCATION}/communes`,
        new: `${ROOTS.DASHBOARD_LOCATION}/communes/new`,
        edit: (id) => `${ROOTS.DASHBOARD_LOCATION}/communes/${id}/edit`,
      },
      villages: {
        root: `${ROOTS.DASHBOARD_LOCATION}/villages`,
        new: `${ROOTS.DASHBOARD_LOCATION}/villages/new`,
        edit: (id) => `${ROOTS.DASHBOARD_LOCATION}/villages/${id}/edit`,
      },
    },
    // WEBSITE
    website: {
      root: `${ROOTS.DASHBOARD_WEBSITE}`,
      postCreate: `${ROOTS.DASHBOARD_WEBSITE}/posts/create`,
      postEdit: (id) => `${ROOTS.DASHBOARD_WEBSITE}/posts/${id}/edit`,
      postShow: (id) => `${ROOTS.DASHBOARD_WEBSITE}/posts/${id}`,

      blogs: {
        root: `${ROOTS.DASHBOARD_WEBSITE}/blogs`,
        new: `${ROOTS.DASHBOARD_WEBSITE}/blogs/create`,
        edit: (id) => `${ROOTS.DASHBOARD_WEBSITE}/blogs/${id}/edit`,
      },

      blocks: `${ROOTS.DASHBOARD_WEBSITE}/blocks`,
      blockCreate: `${ROOTS.DASHBOARD_WEBSITE}/blocks/create`,
      blockEdit: (id) => `${ROOTS.DASHBOARD_WEBSITE}/blocks/${id}/edit`,

      categories: `${ROOTS.DASHBOARD_WEBSITE}/categories`,
      categoryCreate: `${ROOTS.DASHBOARD_WEBSITE}/categories/create`,
      categoryEdit: (id) => `${ROOTS.DASHBOARD_WEBSITE}/categories/${id}/edit`,

      pages: `${ROOTS.DASHBOARD_WEBSITE}/pages`,
      pageCreate: `${ROOTS.DASHBOARD_WEBSITE}/pages/create`,
      pageEdit: (id) => `${ROOTS.DASHBOARD_WEBSITE}/pages/${id}/edit`,

      sliders: `${ROOTS.DASHBOARD_WEBSITE}/sliders`,
      sliderCreate: `${ROOTS.DASHBOARD_WEBSITE}/sliders/create`,
      sliderEdit: (id) => `${ROOTS.DASHBOARD_WEBSITE}/sliders/${id}/edit`,

    },
    // Settings
    settings: {
      requested: {
        root: `${ROOTS.DASHBOARD_SETTINGS}/requested`,
        new: `${ROOTS.DASHBOARD_SETTINGS}/requested/create`,
        edit: (id) => `${ROOTS.DASHBOARD_SETTINGS}/requested/${id}/edit`,
        statuses: `${ROOTS.DASHBOARD_SETTINGS}/requested/statuses`,
        statusEdit: (id) => `${ROOTS.DASHBOARD_SETTINGS}/requested/statuses/${id}/edit`,
        tasks: `${ROOTS.DASHBOARD_SETTINGS}/requested/tasks`,
        taskNew: `${ROOTS.DASHBOARD_SETTINGS}/requested/tasks/create`,
        taskEdit: (id) => `${ROOTS.DASHBOARD_SETTINGS}/requested/tasks/${id}/edit`,
      },
      // Property
      property: {
        root: `${ROOTS.DASHBOARD_SETTINGS}/property`,
        bankNew: `${ROOTS.DASHBOARD_SETTINGS}/property/banks/create`,
        bankShow: (id) => `${ROOTS.DASHBOARD_SETTINGS}/property/banks/${id}`,
        bankEdit: (id) => `${ROOTS.DASHBOARD_SETTINGS}/property/banks/${id}/edit`,
        landTitles: {
          root: `${ROOTS.DASHBOARD_SETTINGS}/property/land-titles`,
          new: `${ROOTS.DASHBOARD_SETTINGS}/property/land-titles/create`,
          edit: (id) => `${ROOTS.DASHBOARD_SETTINGS}/property/land-titles/${id}/edit`,
        },
        main: {
          root: `${ROOTS.DASHBOARD_SETTINGS}/property/main-features`,
          new: `${ROOTS.DASHBOARD_SETTINGS}/property/main-features/create`,
          edit: (id) => `${ROOTS.DASHBOARD_SETTINGS}/property/main-features/${id}/edit`,
        },
        features: {
          root: `${ROOTS.DASHBOARD_SETTINGS}/property/property-features`,
          new: `${ROOTS.DASHBOARD_SETTINGS}/property/property-features/create`,
          edit: (id) => `${ROOTS.DASHBOARD_SETTINGS}/property/property-features/${id}/edit`,
        },
        purposes: {
          root: `${ROOTS.DASHBOARD_SETTINGS}/property/purposes`,
          new: `${ROOTS.DASHBOARD_SETTINGS}/property/purposes/create`,
          edit: (id) => `${ROOTS.DASHBOARD_SETTINGS}/property/purposes/${id}/edit`,
        },
        types: {
          root: `${ROOTS.DASHBOARD_SETTINGS}/property/types`,
          new: `${ROOTS.DASHBOARD_SETTINGS}/property/types/create`,
          edit: (id) => `${ROOTS.DASHBOARD_SETTINGS}/property/types/${id}/edit`,
        },
      },
      loans: {
        root: `${ROOTS.DASHBOARD_SETTINGS}/loans`,
        new: `${ROOTS.DASHBOARD_SETTINGS}/loans/create`,
        edit: (id) => `${ROOTS.DASHBOARD_SETTINGS}/loans/${id}/edit`,
        statuses: `${ROOTS.DASHBOARD_SETTINGS}/loans/statuses`,
        statusEdit: (id) => `${ROOTS.DASHBOARD_SETTINGS}/loans/statuses/${id}/edit`,
      },
      people: {
        root: `${ROOTS.DASHBOARD_SETTINGS}/people`,
        typeCreate: `${ROOTS.DASHBOARD_SETTINGS}/people/create`,
        typeEdit: (id) => `${ROOTS.DASHBOARD_SETTINGS}/people/${id}/edit`,
        departments: `${ROOTS.DASHBOARD_SETTINGS}/people/departments`,
        departmentCreate: `${ROOTS.DASHBOARD_SETTINGS}/people/departments/create`,
        departmentEdit: (id) => `${ROOTS.DASHBOARD_SETTINGS}/people/departments/${id}/edit`,
        positions: `${ROOTS.DASHBOARD_SETTINGS}/people/positions`,
        positionCreate: `${ROOTS.DASHBOARD_SETTINGS}/people/positions/create`,
        positionEdit: (id) => `${ROOTS.DASHBOARD_SETTINGS}/people/positions/${id}/edit`,
        titles: `${ROOTS.DASHBOARD_SETTINGS}/people/titles`,
        titleCreate: `${ROOTS.DASHBOARD_SETTINGS}/people/titles/create`,
        titleEdit: (id) => `${ROOTS.DASHBOARD_SETTINGS}/people/titles/${id}/edit`,
        roles: `${ROOTS.DASHBOARD_SETTINGS}/people/roles`,
        roleCreate: `${ROOTS.DASHBOARD_SETTINGS}/people/roles/create`,
        roleEdit: (id) => `${ROOTS.DASHBOARD_SETTINGS}/people/roles/${id}/edit`,
      },
      wallets: {
        root: `${ROOTS.DASHBOARD_SETTINGS}/wallets`,
        new: `${ROOTS.DASHBOARD_SETTINGS}/wallets/create`,
        edit: (id) => `${ROOTS.DASHBOARD_SETTINGS}/wallets/${id}/edit`,
        categories:{
          root: `${ROOTS.DASHBOARD_SETTINGS}/wallets/categories`,
          new: `${ROOTS.DASHBOARD_SETTINGS}/wallets/categories/create`,
          edit: (id) => `${ROOTS.DASHBOARD_SETTINGS}/wallets/categories/${id}/edit`,
        },
        merchants:{
          root: `${ROOTS.DASHBOARD_SETTINGS}/wallets/merchants`,
          new: `${ROOTS.DASHBOARD_SETTINGS}/wallets/merchants/create`,
          edit: (id) => `${ROOTS.DASHBOARD_SETTINGS}/wallets/merchants/${id}/edit`,
        },
        types: {
          root: `${ROOTS.DASHBOARD_SETTINGS}/wallets/types`,
          new: `${ROOTS.DASHBOARD_SETTINGS}/wallets/types/create`,
          edit: (id) => `${ROOTS.DASHBOARD_SETTINGS}/wallets/types/${id}/edit`,
        },
      },
      website: {
        root: `${ROOTS.DASHBOARD_SETTINGS}/website`,
        menu: `${ROOTS.DASHBOARD_SETTINGS}/website/menu`,
      },
    },
  },
  // ME
  me: {
    root: ROOTS.ME,
    bank: {
      root: `${ROOTS.ME}/requested`,
      new: `${ROOTS.ME}/requested/create`,
      edit: (id) => `${ROOTS.ME}/requested/${id}/edit`,
      show: (id) => `${ROOTS.ME}/requested/${id}`,
    },
    blogs: {
      root: `${ROOTS.ME}/blogs`,
      new: `${ROOTS.ME}/blogs/create`,
      edit: (id) => `${ROOTS.ME}/blogs/${id}/edit`,
    },
    customers: {
      root: `${ROOTS.ME}/customers`,
      new: `${ROOTS.ME}/customers/create`,
      edit: (id) => `${ROOTS.ME}/customers/${id}/edit`,
      show: (id) => `${ROOTS.ME}/customers/${id}`,
    },
    loans: {
      root: `${ROOTS.ME}/loans`,
      new: `${ROOTS.ME}/loans/create`,
      edit: (id) => `${ROOTS.ME}/loans/${id}/edit`,
      show: (id) => `${ROOTS.ME}/loans/${id}`,
    },
    properties: {
      root: `${ROOTS.ME}/properties`,
      new: `${ROOTS.ME}/properties/create`,
      edit: (id) => `${ROOTS.ME}/properties/${id}/edit`,
    },
    requested: {
      root: `${ROOTS.ME}/requested`,
      new: `${ROOTS.ME}/requested/create`,
      edit: (id) => `${ROOTS.ME}/requested/${id}/edit`,
      show: (id) => `${ROOTS.ME}/requested/${id}`,
    },
    staff: {
      root: `${ROOTS.ME}/staff`,
      new: `${ROOTS.ME}/staff/create`,
      edit: (id) => `${ROOTS.ME}/staff/${id}/edit`,
      show: (id) => `${ROOTS.ME}/staff/${id}`,
    },

    wallet: `${ROOTS.ME}/wallet`,
    profile: `${ROOTS.ME}/profile`,
  },
};
