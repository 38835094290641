import useSWR from 'swr';
import { useMemo } from 'react';
// utils
import axiosInstance, { fetcher } from 'src/utils/axios';

// Get All Data
export function useMeGetRows(locale, model) {
    const URL = `/api/me/${locale}/${model}?rows=1`;
    const { data, isLoading, error, isValidating } =
        useSWR(
            URL,
            fetcher,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json'
                }
            });

    const memoizedValue = useMemo(
        () => ({
            rows: data?.data || [],
            rowsLoading: isLoading,
            rowsError: error,
            rowsValidating: isValidating,
            rowsEmpty: !isLoading && !data?.data.length,
        }),
        [data?.data, error, isLoading, isValidating]
    );

    return memoizedValue;
}

// Get All Data with Pagination
export function useMeGetPaginationRows(locale, model, page = 1, perPage = 25, string = '') {

    const URL = `/api/me/${locale}/${model}${`?page=${page}&perpage=${perPage}&${string}`}`;
    const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

    const memoizedValue = useMemo(
        () => ({
            rows: data?.data || [],
            pagination: data?.pagination || 0,
            rowsLoading: isLoading,
            rowsError: error,
            rowsValidating: isValidating,
            rowsEmpty: !isLoading && !data?.data.length,
        }),
        [data?.data, data?.pagination, error, isLoading, isValidating]
    );

    return memoizedValue;
}

// Show Signle Data
export function useMeGetRow(locale, model, id) {
    const URL = `/api/me/${locale}/${model}/${id}`;
    const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
    const memoizedValue = useMemo(
        () => ({
            row: data?.data || [],
            rowLoading: isLoading,
            rowError: error,
            rowValidating: isValidating,
            rowEmpty: !isLoading && !data?.data.length,
        }),
        [data?.data, error, isLoading, isValidating]
    );

    return memoizedValue;
}

// Create and Update Form Data
export async function saveMeFormData(locale, model, formData, id = '') {

    const URL = `/api/me/${locale}/${model}${id !== '' ? `/${id}` : ''}`;

    const res = await axiosInstance.post(URL, formData,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        }
    );
    return res;
}
export async function DeleteImage(locale, puuid, uuid) {

    const URL = `/api/me/${locale}/properties/media/${puuid}/${uuid}`;
    const res = await axiosInstance.delete(URL);
    return res;
}

export async function DeleteMeData(locale, model, id) {

    const URL = `/api/${locale}/${model}/${id}`;
    const res = await axiosInstance.delete(URL);
    return res;
}

// Get All Data
export function useMeProfile(locale) {
    const URL = `/api/${locale}/profile`;
    const { data, isLoading, error, isValidating } =
        useSWR(
            URL,
            fetcher,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json'
                }
            });

    const memoizedValue = useMemo(
        () => ({
            profile: data?.user || [],
            profileLoading: isLoading,
            profileError: error,
            profileValidating: isValidating,
            profileEmpty: !isLoading && !data?.user.length,
        }),
        [data?.user, error, isLoading, isValidating]
    );

    return memoizedValue;
}
