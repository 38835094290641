import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import Checkbox from '@mui/material/Checkbox';
import { Box, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import FormGroup from '@mui/material/FormGroup';
import Image from 'src/components/image';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel, { formControlLabelClasses } from '@mui/material/FormControlLabel';
import { useState } from 'react';

// ----------------------------------------------------------------------

export function RHFCheckbox({ name, helperText, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <FormControlLabel control={<Checkbox {...field} checked={field.value} />} {...other} />

          {(!!error || helperText) && (
            <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
          )}
        </div>
      )}
    />
  );
}

RHFCheckbox.propTypes = {
  helperText: PropTypes.string,
  name: PropTypes.string,
};

export function RHFSelectButton({ options, name }) {
  const { control } = useFormContext();
  const [active, setActive] = useState(false);
  const handleClickButton = (field) => {
    setActive(true);
    if (active === true) {
      setActive(false);
     
    }else{
      console.log(field.value);
    }

  };
 

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        options.map((option)=>(
          <Box
          sx={{
            border: '1px solid red',
            backgroundColor: active ? 'red' : 'white',
            p: 3,
            ':hover': {
              cursor: "pointer",
            }
          }}
          onClick={() => {
            handleClickButton(option.value);
            // eslint-disable-next-line react/prop-types
           
          }}
        >
        
          <Typography>{option.label}</Typography>

        </Box>
        ))
      )}
    />
  );
};

RHFSelectButton.propTypes = {
  name: PropTypes.string,
  options: PropTypes.object,
}


// ----------------------------------------------------------------------
export function RHFMultiCheckbox({ row, name, label, options, spacing, helperText, sx, ...other }) {
  const { control } = useFormContext();

  const getSelected = (selectedItems, item) =>
    selectedItems.includes(item)
      ? selectedItems.filter((value) => value !== item)
      : [...selectedItems, item];

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl component="fieldset">
          {label && (
            <FormLabel component="legend" sx={{ typography: 'body2' }}>
              {label}
            </FormLabel>
          )}

          <FormGroup
            sx={{
              width:"100%",
              ...(row && {
                flexDirection: 'row',
              }),
              [`& .${formControlLabelClasses.root}`]: {
                '&:not(:last-of-type)': {
                  mb: spacing || 0,
                },
                ...(row && {
                  mr: 0,
                  '&:not(:last-of-type)': {
                    mr: spacing || 2,
                  },
                }),
              },
              ...sx,
            }}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={field.value.includes(option.value)}
                    onChange={() => field.onChange(getSelected(field.value, option.value))}
                    disabled={option.disabled}
                  />
                }
                label={option.label}

                {...other}
              />
            ))}
          </FormGroup>

          {(!!error || helperText) && (
            <FormHelperText error={!!error} sx={{ mx: 0 }}>
              {error ? error?.message : helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}

RHFMultiCheckbox.propTypes = {
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  row: PropTypes.bool,
  spacing: PropTypes.number,
  sx: PropTypes.object,
};

export function RHFMultiCheckboxImage({ row, name, label, options, spacing, helperText, sx, ...other }) {
  const { control } = useFormContext();

  const getSelected = (selectedItems, item) =>
    selectedItems.includes(item)
      ? selectedItems.filter((value) => value !== item)
      : [...selectedItems, item];

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl
          component="fieldset"
          sx={{
           width: "100%",
         }}>
          {label && (
            <FormLabel component="legend" sx={{ typography: 'body2' }}>
              {label}
            </FormLabel>
          )}

          <FormGroup
          >
            <div className="grid gap-5 grid-cols-6 text-center">
              {options.map((option) => (
                <div>
                  <Image src={option.image} ratio="21/9" sx={{ borderRadius: 1, }} />
                  <FormControlLabel
                    key={option.value}
                    labelPlacement="bottom"
                    control={
                      <Checkbox
                        checked={field.value.includes(option.value)}
                        onChange={() => field.onChange(getSelected(field.value, option.value))}
                        disabled={option.disabled}
                      />
                    }
                    label={
                      <Box display="flex">

                        <Typography>{option.label}</Typography>
                      </Box>}

                    {...other}
                  />
                </div>
              ))}
            </div>
          </FormGroup>

          {(!!error || helperText) && (
            <FormHelperText error={!!error} sx={{ mx: 0 }}>
              {error ? error?.message : helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}

RHFMultiCheckboxImage.propTypes = {
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  row: PropTypes.bool,
  spacing: PropTypes.number,
  sx: PropTypes.object,
};
