import useSWR from 'swr';
import { useMemo } from 'react';
// utils
import axiosInstance, { fetcher } from 'src/utils/axios';
import { useLocales } from 'src/locales';

export function useGetDashaboard(locale) {
    const URL = `/api/${locale}`;

    const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
    const memoizedValue = useMemo(
        () => ({
            chart: data?.chart || [],
            totalLoans: data?.totalLoans || [],
            totalMembers: data?.totalMembers || [],
            totalProperties: data?.totalProperties || [],
            totalRequested: data?.totalRequested || [],
            rowLoading: isLoading,
            rowError: error,
            rowValidating: isValidating,
            rowEmpty: !isLoading && !data?.length,
        }),
        [
            data?.chart,
            data?.totalLoans,
            data?.totalMembers,
            data?.totalProperties,
            data?.totalRequested, error, isLoading, isValidating, data?.length]
    );

    return memoizedValue;
}

// Get All Data
export function useGetRows(locale, model, select = '') {
    const URL = `/api/${locale}/${model}${select !== '' ? '?select=1' : ''}`;
    const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

    const memoizedValue = useMemo(
        () => ({
            rows: data?.data || [],
            rowsLoading: isLoading,
            rowsError: error,
            rowsValidating: isValidating,
            // rowsEmpty: !isLoading && !data?.data.length,
        }),
        [data?.data, error, isLoading, isValidating]
    );

    return memoizedValue;
}
export function GetRowsId(locale, model, id = '') {
    const URL = `/api/${locale}/${model}/${id === undefined ? '' : id}`;
    const { data, isLoading, error, isValidating } =
        useSWR(
            URL,
            fetcher,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json'
                }
            });

    const memoizedValue = useMemo(
        () => ({
            rows: data?.data || [],
            rowsLoading: isLoading,
            rowsError: error,
            rowsValidating: isValidating,
            rowsEmpty: !isLoading && !data?.data.length,
        }),
        [data?.data, error, isLoading, isValidating]
    );

    return memoizedValue;
}


// Get All Data with Pagination
export function useGetPaginationRows(locale, model, select = '', page = 1, perPage = 25, string = '') {

    const URL = `/api/${locale}/${model}${select !== '' ? `?select=1&?page=${page}&perpage=${perPage}` : `?page=${page}&perpage=${perPage}&${string}`}`;
    const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

    const memoizedValue = useMemo(
        () => ({
            rows: data?.data.data || [],
            pagination: data?.data.pagination || 0,
            rowsLoading: isLoading,
            rowsError: error,
            rowsValidating: isValidating,
            rowsEmpty: !isLoading && !data?.data.length,
        }),
        [data?.data, error, isLoading, isValidating]
    );

    return memoizedValue;
}

// Get All Data with Pagination
export function useGetRowsPagination(locale, model, select = '', page = 1, perPage = 25, string = '') {

    const URL = `/api/${locale}/${model}${select !== '' ? `?select=1&?page=${page}&perpage=${perPage}` : `?page=${page}&perpage=${perPage}&${string}`}`;
    const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

    const memoizedValue = useMemo(
        () => ({
            rows: data?.data || [],
            pagination: data?.pagination || 0,
            rowsLoading: isLoading,
            rowsError: error,
            rowsValidating: isValidating,
            rowsEmpty: !isLoading && !data?.data.length,
        }),
        [data?.data, error, isLoading, isValidating, data?.pagination]
    );

    return memoizedValue;
}

// Show Signle Data
export function useGetRow(locale, model, id = null) {
    const URL = `/api/${locale}/${model}${id === null ? '' : `/${id}`}`;

    const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
    const memoizedValue = useMemo(
        () => ({
            row: data?.data || [],
            rowLoading: isLoading,
            rowError: error,
            rowValidating: isValidating,
            rowEmpty: !isLoading && !data?.data.length,
        }),
        [data?.data, error, isLoading, isValidating]
    );

    return memoizedValue;
}

// Create and Update Form Data
export async function saveFormData(locale, model, formData, id = null) {

    const URL = `/api/${locale}/${model}${id !== null ? `/${id}` : ''}`;
    const res = await axiosInstance.post(URL, formData,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        }
    );
    return res;
}

// Get Page by Slug
export function useGetPageBySlug(locale, slug) {
    const URL = `api/${locale}/web/${slug}`;
    const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
    const memoizedValue = useMemo(
        () => ({
            page: data ?? [],
            pageLoading: isLoading,
            pageError: error,
            pageValidating: isValidating
        }),
        [data, error, isLoading, isValidating]
    );
    return memoizedValue;
}

export function useLocations(type, id, page = 1, perpage = 25, filterName = '') {
    const { currentLang } = useLocales();
    const { data, isLoading, error, isValidating } = useSWR(`/api/${currentLang.value}/locations/${type}/${id === undefined ? '' : id}?page=${page}&perpage=${perpage}&name=${filterName}`, fetcher);

    const memoizedValue = useMemo(
        () => ({
            rows: data?.data.data || [],
            pagination: data?.data.pagination || 0,
            rowLoading: isLoading,
            rowError: error,
            rowValidating: isValidating,
            rowEmpty: !isLoading && !data?.data.length,
        }),
        [data?.data, error, isLoading, isValidating]
    );

    return memoizedValue;
}

export function useDashboard(locale) {
    const URL = `/api/${locale}/dashboard`;
    const { data, isLoading, error, isValidating } =
        useSWR(
            URL,
            fetcher,
            { headers: { 'Content-Type': 'application/json', Accept: 'application/json' } }
        );

    const memoizedValue = useMemo(
        () => ({
            totalProperties: data?.totalProperties || [],
            totalIndications: data?.totalIndications || [],
            totalMembers: data?.totalMembers || [],
            indications: data?.indications || [],
            statuses: data?.statuses || [],
            rowsLoading: isLoading,
            rowsError: error,
            rowsValidating: isValidating,
            rowsEmpty: !isLoading && !data?.data.length,
        }),
        [data, error, isLoading, isValidating]
    );

    return memoizedValue;
}

// Delete Media Gallery
export async function deleteMediaSingleImage(locale, model, id, mediaId) {
  const URL = `/api/${locale}/${model}/${id}/${mediaId}`;
  const res = await axiosInstance.delete(URL,
    { headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data', },}
  );
  return res;
}