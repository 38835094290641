import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard, PermissionBasedGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import {MerchantTypeListView} from "../../sections/dashboard/wallets/types/view";
// ----------------------------------------------------------------------

// Dashboard
const IndexPage = lazy(() => import('src/pages/dashboard/app'));


// Land and Properties pages
// ------------------------------------------------------------------------------------------------

// const PropertiesListPage = lazy(() => import('src/pages/dashboard/properties/property/list'));
// const PropertiesNewPage = lazy(() => import('src/pages/dashboard/properties/property/create'));

const RequestedListPage = lazy(() => import('src/pages/dashboard/requested/list'));
const RequestedEditPage = lazy(() => import('src/pages/dashboard/requested/edit'));
const RequestedShowPage = lazy(() => import('src/pages/dashboard/requested/show'));
const RequestedStatusListPage = lazy(() => import('src/pages/dashboard/requested/status/list'));
const RequestedStatusEditPage = lazy(() => import('src/pages/dashboard/requested/status/edit'));
const RequestedTaskListPage = lazy(() => import('src/pages/dashboard/requested/task/list'));
const RequestedTaskCreatePage = lazy(() => import('src/pages/dashboard/requested/task/create'));
const RequestedTaskEditPage = lazy(() => import('src/pages/dashboard/requested/task/edit'));
const RequestedTypeListPage = lazy(() => import('src/pages/dashboard/requested/type/list'));
const RequestedTypeCreatePage = lazy(() => import('src/pages/dashboard/requested/type/create'));
const RequestedTypeEditPage = lazy(() => import('src/pages/dashboard/requested/type/edit'));

const BanksListPage = lazy(() => import('src/pages/dashboard/bank-branches/banks/list'));
const BanksCreatePage = lazy(() => import('src/pages/dashboard/bank-branches/banks/new'));
const BankEditPage = lazy(() => import('src/pages/dashboard/bank-branches/banks/edit'));
const BranchListPage = lazy(() => import('src/pages/dashboard/bank-branches/list'));
const BranchNewPage = lazy(() => import('src/pages/dashboard/bank-branches/new'));
const BranchEditPage = lazy(() => import('src/pages/dashboard/bank-branches/edit'));

const BankBlogListPage = lazy(() => import('src/pages/dashboard/bank-branches/blogs/list'));
const BankBlogEditPage = lazy(() => import('src/pages/dashboard/bank-branches/blogs/edit'));
const BankBlogShowPage = lazy(() => import('src/pages/dashboard/bank-branches/blogs/show'));

const PropertyPurposeListPage = lazy(() => import('src/pages/dashboard/properties/purpose/list'));
const PropertyPurposeCreatePage = lazy(() => import('src/pages/dashboard/properties/purpose/create'));

const PropertyListPage = lazy(() => import('src/pages/dashboard/properties/property/list'));
const PropertyCreatePage = lazy(() => import('src/pages/dashboard/properties/property/create'));
const PropertyEditPage = lazy(() => import('src/pages/dashboard/properties/property/edit'));
const PropertyShowPage = lazy(() => import('src/pages/dashboard/properties/property/show'));

const PropertyPurposeEditPage = lazy(() => import('src/pages/dashboard/properties/purpose/edit'));
const PropertyTypeListPage = lazy(() => import('src/pages/dashboard/properties/type/list'));
const PropertyTypeCreatePage = lazy(() => import('src/pages/dashboard/properties/type/create'));
const PropertyTypeEditPage = lazy(() => import('src/pages/dashboard/properties/type/edit'));
const LandTitleListPage = lazy(() => import('src/pages/dashboard/properties/land-title/list'));
const LandTitleCreatePage = lazy(() => import('src/pages/dashboard/properties/land-title/create'));
const LandTitleEditPage = lazy(() => import('src/pages/dashboard/properties/land-title/edit'));
const MainFeatureListPage = lazy(() => import('src/pages/dashboard/properties/main-feature/list'));
const MainFeatureCreatePage = lazy(() => import('src/pages/dashboard/properties/main-feature/create'));
const MainFeatureEditPage = lazy(() => import('src/pages/dashboard/properties/main-feature/edit'));
const PropertyFeatureListPage = lazy(() => import('src/pages/dashboard/properties/property-feature/list'));
const PropertyFeatureCreatePage = lazy(() => import('src/pages/dashboard/properties/property-feature/create'));
const PropertyFeatureEditPage = lazy(() => import('src/pages/dashboard/properties/property-feature/edit'));
// ------------------------------------- Loans
const LoanListsPage = lazy(() => import('src/pages/dashboard/loan/list'));
const LoanEditPage = lazy(() => import('src/pages/dashboard/loan/edit'));
const LoanShowPage = lazy(() => import('src/pages/dashboard/loan/show'));
const LoanStatusListPage = lazy(() => import('src/pages/dashboard/loan/status/list'));
const LoanStatusEditPage = lazy(() => import('src/pages/dashboard/loan/status/edit'));
const LoanTypeListPage = lazy(() => import('src/pages/dashboard/loan/type/list'));
const LoanTypeCreatePage = lazy(() => import('src/pages/dashboard/loan/type/create'));
const LoanTypeEditPage = lazy(() => import('src/pages/dashboard/loan/type/edit'));
// ------------------------------------------------------------------------------------------------
const ProvincesListPage = lazy(() => import('src/pages/dashboard/location/province/list'));
const ProvinceNewPage = lazy(() => import('src/pages/dashboard/location/province/new'));
const ProvinceEditPage = lazy(() => import('src/pages/dashboard/location/province/edit'));
const DistrictsListPage = lazy(() => import('src/pages/dashboard/location/district/list'));
const DistrictNewPage = lazy(() => import('src/pages/dashboard/location/district/new'));
const DistrictEditPage = lazy(() => import('src/pages/dashboard/location/district/edit'));
const CommunesListPage = lazy(() => import('src/pages/dashboard/location/commune/list'));
const CommuneNewPage = lazy(() => import('src/pages/dashboard/location/commune/new'));
const CommuneEditPage = lazy(() => import('src/pages/dashboard/location/commune/edit'));
const VillagesListPage = lazy(() => import('src/pages/dashboard/location/village/list'));

// ------------------------------------------------------------------------------------------------
const MapsViewPage = lazy(() => import('src/pages/dashboard/properties/maps/list'));
// ------------------------------------------------------------------------------------------------

// setting
// ------------------------------------------------------------------------------------------------

const WebsiteSettingPage = lazy(() => import('src/pages/dashboard/website/setting/setting'));
const MenuListPage = lazy(() => import('src/pages/dashboard/website/setting/menu'));
// ------------------------------------------------------------------------------------------------

// Website
// ------------------------------------------------------------------------------------------------

const BlocksListPage = lazy(() => import('src/pages/dashboard/website/blocks/list'));
const BlockCreatePage = lazy(() => import('src/pages/dashboard/website/blocks/create'));
const BlockEditPage = lazy(() => import('src/pages/dashboard/website/blocks/edit'));

const CategoriesListPage = lazy(() => import('src/pages/dashboard/website/categories/list'));
const CategoryCreatePage = lazy(() => import('src/pages/dashboard/website/categories/create'));
const CategoryEditPage = lazy(() => import('src/pages/dashboard/website/categories/edit'));

const PostsListPage = lazy(() => import('src/pages/dashboard/website/posts/list'));
const PostCreatePage = lazy(() => import('src/pages/dashboard/website/posts/new'));
const PostEditPage = lazy(() => import('src/pages/dashboard/website/posts/edit'));

const PagesListPage = lazy(() => import('src/pages/dashboard/website/pages/list'));
const PageCreatePage = lazy(() => import('src/pages/dashboard/website/pages/new'));
const PageEditPage = lazy(() => import('src/pages/dashboard/website/pages/edit'));


const SlidersListPage = lazy(() => import('src/pages/dashboard/website/sliders/list'));
const SliderCreatePage = lazy(() => import('src/pages/dashboard/website/sliders/create'));
const SliderEditPage = lazy(() => import('src/pages/dashboard/website/sliders/edit'));

// Website
// ------------------------------------------------------------------------------------------------

const PeopleListPage = lazy(() => import('src/pages/dashboard/people/people/list'));
const PeopleCreatePage = lazy(() => import('src/pages/dashboard/people/people/create'));
const PeopleEditPage = lazy(() => import('src/pages/dashboard/people/people/edit'));
const PeopleTypeListPage = lazy(() => import('src/pages/dashboard/people/type/list'));
const PeopleTypeCreatePage = lazy(() => import('src/pages/dashboard/people/type/create'));
const PeopleTypeEditPage = lazy(() => import('src/pages/dashboard/people/type/edit'));
const DepartmentListPage = lazy(() => import('src/pages/dashboard/people/department/list'));
const DepartmentCreatePage = lazy(() => import('src/pages/dashboard/people/department/create'));
const DepartmentEditPage = lazy(() => import('src/pages/dashboard/people/department/edit'));
const PositionListPage = lazy(() => import('src/pages/dashboard/people/position/list'));
const PositionCreatePage = lazy(() => import('src/pages/dashboard/people/position/create'));
const PositionEditPage = lazy(() => import('src/pages/dashboard/people/position/edit'));
const PeopleTitleListPage = lazy(() => import('src/pages/dashboard/people/title/list'));
const PeopleTitleCreatePage = lazy(() => import('src/pages/dashboard/people/title/create'));
const PeopleTitleEditPage = lazy(() => import('src/pages/dashboard/people/title/edit'));
const RoleListPage = lazy(() => import('src/pages/dashboard/people/role/list'));
const RoleCreatePage = lazy(() => import('src/pages/dashboard/people/role/create'));
const RoleEditPage = lazy(() => import('src/pages/dashboard/people/role/edit'));

// Wallet
const CurrencyListPage = lazy(() => import('src/pages/dashboard/wallet/currency/list'));
const CurrencyCreatePage = lazy(() => import('src/pages/dashboard/wallet/currency/create'));
const CurrencyEditPage = lazy(() => import('src/pages/dashboard/wallet/currency/edit'));

const MerchantListPage = lazy(() => import('src/pages/dashboard/wallet/merchant/list'));
const MerchantCreatePage = lazy(() => import('src/pages/dashboard/wallet/merchant/create'));
const MerchantEditPage = lazy(() => import('src/pages/dashboard/wallet/merchant/edit'));

const MerchantTypeListPage = lazy(() => import('src/pages/dashboard/wallet/type/list'));
const MerchantTypeCreatePage = lazy(() => import('src/pages/dashboard/wallet/type/create'));
const MerchantTypeEditPage = lazy(() => import('src/pages/dashboard/wallet/type/edit'));

const TransactionCategoryListPage = lazy(() => import('src/pages/dashboard/wallet/category/list'));
const TransactionCategoryCreatePage = lazy(() => import('src/pages/dashboard/wallet/category/create'));
const TransactionCategoryEditPage = lazy(() => import('src/pages/dashboard/wallet/category/edit'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
    {
        path: 'dashboard',
        element: (
            <PermissionBasedGuard permission='view_administrator' >
                <AuthGuard>
                    <DashboardLayout>
                        <Suspense fallback={<LoadingScreen />}>
                            <Outlet />
                        </Suspense>
                    </DashboardLayout>
                </AuthGuard>
            </PermissionBasedGuard>
        ),
        children: [
            { element: <IndexPage />, index: true },
            // ---------------------------------------------- Requested
            {
                path: 'requested',
                children: [

                    { element: (<PermissionBasedGuard permission='view_requested' >
                              <RequestedListPage />
                          </PermissionBasedGuard>
                        ), index: true },
                    {
                        path: ':id/edit',
                        element: (<PermissionBasedGuard permission='update_requested' >
                              <RequestedEditPage />
                            </PermissionBasedGuard>
                        )
                    },
                    {
                        path: ':id',
                        element: (<PermissionBasedGuard permission='view_requested' >
                              <RequestedShowPage />
                          </PermissionBasedGuard>
                        )
                    },
                ]
            },
            // ---------------------------------------------- Properties
            {
                path: 'properties',
                children: [
                    {
                        element: (<PermissionBasedGuard permission='view_property' >
                              <PropertyListPage />
                          </PermissionBasedGuard>
                        ), index: true
                    },
                    {
                        path: 'create',
                        element: (<PermissionBasedGuard permission='create_property' >
                              <PropertyCreatePage />
                          </PermissionBasedGuard>
                        )
                    },
                    {
                        path: ':id/edit',
                        element: (<PermissionBasedGuard permission='update_property' >
                              <PropertyEditPage />
                          </PermissionBasedGuard>
                        )
                    },
                    {
                        path: ':id',
                        element: (<PermissionBasedGuard permission='show_property' >
                              <PropertyShowPage />
                          </PermissionBasedGuard>
                        )
                    },
                ]
            },
            // ---------------------------------------------- loans
            {
                path: 'loans',
                children: [
                    {
                        element: (<PermissionBasedGuard permission='view_loan' >
                              <LoanListsPage />
                          </PermissionBasedGuard>
                        ), index: true
                    },
                    {
                        path: ':id/edit',
                        element: (<PermissionBasedGuard permission='update_loan' >
                              <LoanEditPage />
                          </PermissionBasedGuard>
                        )
                    },
                    {
                        path: ':id',
                        element: (<PermissionBasedGuard permission='view_loan' >
                              <LoanShowPage />
                          </PermissionBasedGuard>
                        )
                    },
                ]
            },
            // ---------------------------------------------- bank branches
            {
                path: 'bank-branches',
                children: [

                    { element: (<PermissionBasedGuard permission='view_bank' >
                              <BranchListPage />
                          </PermissionBasedGuard>
                        ), index: true },
                    {
                        path: 'create',
                        element: (<PermissionBasedGuard permission='create_bank' >
                              <BranchNewPage />
                          </PermissionBasedGuard>
                        )
                    },
                    {
                        path: ':id/edit',
                        element: (<PermissionBasedGuard permission='update_bank' >
                              <BranchEditPage />
                          </PermissionBasedGuard>
                        )
                    },
                ]
            },

            {
                path: 'locations',
                children: [
                    { element: <ProvincesListPage />, index: true },
                    { path: 'new', element: <ProvinceNewPage /> },
                    { path: ':id/edit', element: <ProvinceEditPage /> },
                    { path: 'districts', element: <DistrictsListPage /> },
                    { path: 'districts/new', element: <DistrictNewPage /> },
                    { path: 'districts/:id/edit', element: <DistrictEditPage /> },
                    { path: 'communes', element: <CommunesListPage /> },
                    { path: 'communes/new', element: <CommuneNewPage /> },
                    { path: 'communes/:id/edit', element: <CommuneEditPage /> },
                    { path: 'villages', element: <VillagesListPage /> },
                ]
            },
            // --------------------------------------------------------------------------------------------
            {
                path: 'people',
                children: [
                    { element: <PeopleListPage />, index: true },
                    { path: 'create', element: <PeopleCreatePage /> },
                    { path: ':id/edit', element: <PeopleEditPage /> },
                ],
            },
            // --------------------------------------------------------------------------------------------

            {
                path: 'settings',
                children: [
                    { element: <RequestedTypeListPage />, index: true },
                    {
                        path: 'requested',
                        children: [
                            { element: <RequestedTypeListPage />, index: true },
                            { path: 'create', element: <RequestedTypeCreatePage /> },
                            { path: ':id/edit', element: <RequestedTypeEditPage /> },
                            { path: 'statuses', element: <RequestedStatusListPage /> },
                            { path: 'statuses/:id/edit', element: <RequestedStatusEditPage /> },
                            { path: 'tasks', element: <RequestedTaskListPage /> },
                            { path: 'tasks/create', element: <RequestedTaskCreatePage /> },
                            { path: 'tasks/:id/edit', element: <RequestedTaskEditPage /> },
                        ]
                    },
                    {
                        path: 'property',
                        children: [
                            { element: <BanksListPage />, index: true },
                            { path: 'banks/create', element: <BanksCreatePage /> },
                            { path: 'banks/:id/edit', element: <BankEditPage /> },
                            { path: 'land-titles', element: <LandTitleListPage /> },
                            { path: 'land-titles/create', element: <LandTitleCreatePage /> },
                            { path: 'land-titles/:id/edit', element: <LandTitleEditPage /> },
                            { path: 'main-features', element: <MainFeatureListPage /> },
                            { path: 'main-features/create', element: <MainFeatureCreatePage /> },
                            { path: 'main-features/:id/edit', element: <MainFeatureEditPage /> },
                            { path: 'property-features', element: <PropertyFeatureListPage /> },
                            { path: 'property-features/create', element: <PropertyFeatureCreatePage /> },
                            { path: 'property-features/:id/edit', element: <PropertyFeatureEditPage /> },
                            { path: 'purposes', element: <PropertyPurposeListPage /> },
                            { path: 'purposes/create', element: <PropertyPurposeCreatePage /> },
                            { path: 'purposes/:id/edit', element: <PropertyPurposeEditPage /> },
                            { path: 'types', element: <PropertyTypeListPage /> },
                            { path: 'types/create', element: <PropertyTypeCreatePage /> },
                            { path: 'types/:id/edit', element: <PropertyTypeEditPage /> },
                        ]
                    },
                    {
                        path: 'loans',
                        children: [
                            { element: <LoanTypeListPage />, index: true },
                            { path: 'create', element: <LoanTypeCreatePage /> },
                            { path: ':id/edit', element: <LoanTypeEditPage /> },
                            { path: 'statuses', element: <LoanStatusListPage /> },
                            { path: 'statuses/:id/edit', element: <LoanStatusEditPage /> },
                        ]
                    },
                    {
                        path: 'people',
                        children: [
                            { element: <PeopleTypeListPage />, index: true },
                            { path: 'create', element: <PeopleTypeCreatePage /> },
                            { path: ':id/edit', element: <PeopleTypeEditPage /> },
                            { path: 'departments', element: <DepartmentListPage /> },
                            { path: 'departments/create', element: <DepartmentCreatePage /> },
                            { path: 'departments/:id/edit', element: <DepartmentEditPage /> },
                            { path: 'positions', element: <PositionListPage /> },
                            { path: 'positions/create', element: <PositionCreatePage /> },
                            { path: 'positions/:id/edit', element: <PositionEditPage /> },
                            { path: 'titles', element: <PeopleTitleListPage /> },
                            { path: 'titles/create', element: <PeopleTitleCreatePage /> },
                            { path: 'titles/:id/edit', element: <PeopleTitleEditPage /> },
                            { path: 'roles', element: <RoleListPage /> },
                            { path: 'roles/create', element: <RoleCreatePage /> },
                            { path: 'roles/:id/edit', element: <RoleEditPage /> },
                        ],
                    },
                    {
                        path: 'wallets',
                        children: [
                            { element: <CurrencyListPage />, index: true },
                            { path: 'create', element: <CurrencyCreatePage /> },
                            { path: ':id/edit', element: <CurrencyEditPage /> },

                            { path: 'merchants', element: <MerchantListPage /> },
                            { path: 'merchants/create', element: <MerchantCreatePage /> },
                            { path: 'merchants/:id/edit', element: <MerchantEditPage /> },

                            { path: 'types', element: <MerchantTypeListView /> },
                            { path: 'types/create', element: <MerchantTypeCreatePage /> },
                            { path: 'types/:id/edit', element: <MerchantTypeEditPage /> },

                            { path: 'categories', element: <TransactionCategoryListPage /> },
                            { path: 'categories/create', element: <TransactionCategoryCreatePage /> },
                            { path: 'categories/:id/edit', element: <TransactionCategoryEditPage /> },
                        ]
                    },
                    {
                        path: 'website',
                        children: [
                            { element: <WebsiteSettingPage />, index: true },
                            { path: 'menu', element: <MenuListPage /> },
                        ]
                    },
                ]
            },
            // --------------------------------------------------------------------------------------------
            {
                path: 'website',
                children: [
                    { element: <PostsListPage />, index: true },
                    { path: 'posts/create', element: <PostCreatePage /> },
                    { path: 'posts/:id/edit', element: <PostEditPage /> },

                    { path: 'blogs', element: <BankBlogListPage /> },
                    { path: 'blogs/:id/edit', element: <BankBlogEditPage /> },
                    { path: 'blogs/:id', element: <BankBlogShowPage /> },

                    { path: 'blocks', element: <BlocksListPage /> },
                    { path: 'blocks/create', element: <BlockCreatePage /> },
                    { path: 'blocks/:id/edit', element: <BlockEditPage /> },

                    { path: 'categories', element: <CategoriesListPage /> },
                    { path: 'categories/create', element: <CategoryCreatePage /> },
                    { path: 'categories/:id/edit', element: <CategoryEditPage /> },

                    { path: 'pages', element: <PagesListPage /> },
                    { path: 'pages/create', element: <PageCreatePage /> },
                    { path: 'pages/:id/edit', element: <PageEditPage /> },

                    { path: 'sliders', element: <SlidersListPage /> },
                    { path: 'sliders/create', element: <SliderCreatePage /> },
                    { path: 'sliders/:id/edit', element: <SliderEditPage /> },
                ],
            },
        ],
    },
];
