import PropTypes from 'prop-types';
import { useLocales } from 'src/locales';
// @mui
import { useTheme } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function MerchantTypeTableRow({
  row,
  onEditRow,
}) {
  const {t} = useLocales();
  const { name_both, merchants, ordered } = row;
  const theme = useTheme();

  return (
      <TableRow hover>
        <TableCell>{ name_both }</TableCell>
        <TableCell>{ merchants }</TableCell>
        <TableCell>{ ordered }</TableCell>

        <TableCell align="center" sx={{ px: 1 }}>
          <Tooltip title={ t('edit') }>
            <Iconify
              color={ theme.palette.success.main }
              icon="bxs:edit"
              onClick={() => {
                onEditRow();
              }}
            />
          </Tooltip>
        </TableCell>
      </TableRow>
  );
}

MerchantTypeTableRow.propTypes = {
  onEditRow: PropTypes.func,
  row: PropTypes.object,
};
