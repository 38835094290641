import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { Editor } from '@tinymce/tinymce-react';

// @mui
import FormHelperText from '@mui/material/FormHelperText';
import { useLocales } from 'src/locales';
import { TINYMCE_API } from 'src/config-global';


// ----------------------------------------------------------------------

export default function RHFTinyMCE({ name, helperText, height, ...other }) {
  const {
    control,
    watch,
    setValue,
    formState: { isSubmitSuccessful },
  } = useFormContext();

  const values = watch();

  const [source, setSource] = useState();
  const [show, setShow] = useState(false);
  const { t } = useLocales();

  useEffect(() => {

    if (values[name] === '<p><br></p>') {
      setValue(name, '', {
        shouldValidate: !isSubmitSuccessful,
      });
    }
  }, [isSubmitSuccessful, name, setValue, values, source]);

  const handleChange = (e) => {
    setSource(e.target.value);
    setValue(name, e.target.value);
  }


  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          {/* <Button

            sx={{
              my: 2,

            }}
            variant='contained'
            onClick={() => {
              setShow(!show);
              setSource(values[name]);
            }}>{t('editSource')}</Button> */}
          {/* {show && (
            <TextField

              sx={{ my: 2, overflow: 'auto' }}
              fullWidth
              value={source}
              multiline
              rows={12}
              onChange={handleChange}
            />
          )} */}
          <Editor
            // apiKey='tsdnwlq2exu4e7nwglpkig10phdbh3g10tp8f4zg6m5osb19'
            // apiKey='0micdr970vyi7v0l4okhjnmwxwcwh5tfyrwgr4wpbg15ni06'
            apiKey={TINYMCE_API}
            value={field.value}
            id={name}
            onEditorChange={field.onChange}
            error={!!error}
            helperText={
              (!!error || helperText) && (
                <FormHelperText error={!!error} sx={{ px: 2 }}>
                  {error ? error?.message : helperText}
                </FormHelperText>
              )
            }
            {...other}
            init={{
              height: height ?? 500,
              menubar: false,
              selector: field.value,
              plugins: [
                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount ', 'table'
              ],
              toolbar: 'undo redo | blocks | ' +
                'code bold italic forecolor | alignleft aligncenter ' +
                'alignright alignjustify |  bullist numlist outdent indent removeformat image link | table',
              content_style: 'body {  font-size:14px }'
            }}
          />

        </>
      )}
    />

  );
}

RHFTinyMCE.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.string,
  height: PropTypes.number,
};
