// routes
import { paths } from 'src/routes/paths';
import { useLocales } from 'src/locales';
// components
import Iconify from 'src/components/iconify';
import { useMemo } from 'react';

// ----------------------------------------------------------------------



export function useNavigationProfile1() {
  const { t } = useLocales();

  const data = useMemo(()=>[
      {
        title: t('requested'),
        icon: <Iconify icon="solar:atom-bold-duotone" />,
        path: paths.me.requested.root,
      },
      {
        title: t('properties'),
        icon: <Iconify icon="clarity:house-solid" />,
        path: paths.me.properties.root,
      },
      {
        title: t('loans'),
        icon: <Iconify icon="healthicons:money-bag" />,
        path: paths.me.loans.root,
      },
      {
        title: t('staff'),
        roles: ['bank'],
        icon: <Iconify icon="mdi:users" />,
        path: paths.me.staff.root,
      },
      {
        title: t('customers'),
        roles: ['administrator', 'director', 'staff', 'agent', 'bank', 'bank staff'],
        icon: <Iconify icon="mdi:users" />,
        path: paths.me.customers.root,
      },
      {
        title: t('blogs'),
        roles: ['bank'],
        icon: <Iconify icon="mdi:blog" />,
        path: paths.me.blogs.root,
      },
  ],[t]

  );

  return data;
}

export function useNavigationProfile2() {
  const { t } = useLocales();

  const data = useMemo(()=>[
    {
      title: t('wallet'),
      icon: <Iconify icon="ph:wallet-bold" />,
      path: paths.me.wallet,
    },
    {
      title: t('profile'),
      icon: <Iconify icon="uil:cog" />,
      path: paths.me.root,
    },
  ],[t]
  );

  return data;
}
