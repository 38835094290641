import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
// routes
import { usePathname } from 'src/routes/hook';
import {useLocales} from "src/locales";
import {useWebGetRow} from "src/api/web";
//
import Footer from '../main/footer';
import Header from './header';

// ----------------------------------------------------------------------

export default function ProfileLayout({ children }) {
  const pathname = usePathname();
  const { currentLang } = useLocales();
  const { row: siteSetting } = useWebGetRow(currentLang.value, 'settings');

  const isHome = pathname === '/';

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      <Header siteSetting={siteSetting} />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          ...(!isHome && {
            pt: { xs: 8, md: 10 },
          }),
        }}
      >
        {children}
      </Box>
      <Footer siteSetting={siteSetting} />
    </Box>
  );
}

ProfileLayout.propTypes = {
  children: PropTypes.node,
};
