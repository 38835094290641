import PropTypes from "prop-types";
// @mui
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// hooks
import { useRouter } from 'src/routes/hook';
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// theme
import { bgBlur } from 'src/theme/css';
// routes
import {useLocales} from "src/locales";
import {useAuthContext} from "src/auth/hooks";
// components
import Logo from 'src/components/logo';
//
import { HEADER } from '../config-layout';
import NavMobile from './nav/mobile';

import { HeaderShadow, LanguagePopover } from '../_common';
import {useNavigationProfile1, useNavigationProfile2} from './config-navigation';
import NavDesktop from './nav/desktop/nav-desktop';


// ----------------------------------------------------------------------

export default function Header({ siteSetting }) {
    const theme = useTheme();
    const { t } = useLocales();

  const router = useRouter();

  const { logout, user } = useAuthContext();

    const mdUp = useResponsive('up', 'md');

    const navigationProfile1 = useNavigationProfile1();
    const navigationProfile2 = useNavigationProfile2();
    const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  const handleLogout = async () => {
    try {
      await logout();
      router.replace('/');
    } catch (error) {
      console.log("Error Try Catch:");
    }
  };

    return (
        <AppBar>
            <Toolbar
                disableGutters
                sx={{
                    backgroundColor:'grey.200',
                    height: {
                        xs: HEADER.H_MOBILE,
                        md: HEADER.H_DESKTOP,
                    },
                    transition: theme.transitions.create(['height'], {
                        easing: theme.transitions.easing.easeInOut,
                        duration: theme.transitions.duration.shorter,
                    }),
                    ...(offsetTop && {
                        ...bgBlur({
                            color: theme.palette.background.default,
                        }),
                        height: {
                            md: HEADER.H_DESKTOP_OFFSET,
                        },
                    }),
                }}
            >
                <Container maxWidth="xl">
                  {mdUp && (
                    <Grid container spacing={3} sx={{ mt: -1 }}>
                      <Grid sm={5}>
                        <NavDesktop
                          offsetTop={offsetTop}
                          data={navigationProfile1}
                        />
                      </Grid>
                      <Grid sm={2} sx={{ textAlign: "center"}}>
                        <Logo fileURL={siteSetting.logo} sx={{ height: "40px"}} />
                      </Grid>
                      <Grid sm={5} sx={{ textAlign: "right"}}>
                        <Stack
                          direction="row"
                          justifyContent="end"
                          spacing={2}
                        >
                          <NavDesktop
                            offsetTop={offsetTop}
                            data={navigationProfile2}
                          />
                          <Link onClick={handleLogout} sx={{ fontSize: "18px", fontWeight: "bold", mt: 0, color: "black", cursor: "pointer" }}>
                            {t('logout')}
                          </Link>
                          <Box sx={{ mt: -1}}>
                            <LanguagePopover />
                          </Box>
                        </Stack>
                      </Grid>
                    </Grid>
                    )
                  }
                  {!mdUp && (
                    <Container sx={{ display: "flex", pl: 0}}>
                      <NavMobile data={navigationProfile1} data2={navigationProfile2} />
                      <Box sx={{ flexGrow: 1 }} />
                      <Logo fileURL={siteSetting.logo} sx={{ height: "40px"}} />
                      <Box sx={{ flexGrow: 1 }} />
                      <Link onClick={handleLogout} sx={{ fontSize: "18px", fontWeight: "bold", mt: 0.8, color: "black" }}>
                        {t('logout')}
                      </Link>
                      <LanguagePopover  />
                    </Container>
                  )}
                </Container>
            </Toolbar>
            {offsetTop && <HeaderShadow />}
        </AppBar>
    );
}

Header.propTypes = {
  siteSetting: PropTypes.object,
};