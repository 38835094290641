// @mui
import Container from '@mui/material/Container';
// routes
import { useLocales } from 'src/locales';
import { paths } from 'src/routes/paths';
// components
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import LoanTypeNewEditForm from '../form';

// ----------------------------------------------------------------------

export default function MerchantTypeCreateView() {
    const { t } = useLocales();
  return (
    <Container maxWidth= 'xxl'>
      <CustomBreadcrumbs
        heading={ t('loanTypes') }
        links={[
          {
              name: t('dashboard'),
              href: paths.dashboard.root,
          },
          {
              name: t('settings'),
              href: paths.dashboard.settings.loans.root,
          },
          {
              name: t('loanTypes'),
              href: paths.dashboard.settings.loans.root,
          },
          {
            name: t('addNew'),
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <LoanTypeNewEditForm />
    </Container>
  );
}
