import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import SvgColor from 'src/components/svg-color';


// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  property: icon('ic_property'),
  requested: icon('ic_requested'),
  loan: icon('ic_loan'),
  user: icon('ic_user'),
  bank: icon('ic_bank'),
  menuItem: icon('ic_menu_item'),
  dashboard: icon('ic_dashboard'),
  location: icon('ic_location'),
  setting: icon('ic_setting'),
  propertySetting: icon('ic_property_setting'),
  requestedSetting: icon('ic_requested_setting'),
  contentSetting: icon('ic_content'),
  userSetting: icon('ic_user_setting'),
  webSetting: icon('ic_web_setting'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // OVERVIEW
      {
        // subheader: t('overview'),
        items: [
          {
            roles: 'administrator',
            permissions: 'view_administrator',
            title: t('dashboard'), path: paths.dashboard.root, icon: ICONS.dashboard
          },
          {
            permissions: 'view_requested',
            title: t('requested'),
            path: paths.dashboard.requested.root,
            icon: ICONS.requested
          },
          {
            permissions: 'view_property',
            title: t('properties'),
            path: paths.dashboard.properties.root,
            icon: ICONS.property
          },
          {
            permissions: 'view_loan',
            title: t('loans'),
            path: paths.dashboard.loans.root,
            icon: ICONS.loan
          },
          {
            permissions: 'view_people',
            title: t('people'),
            path: paths.dashboard.people.root,
            icon: ICONS.user
          },
          {
            permissions: 'view_bank',
            title: t('bankBranches'),
            path: paths.dashboard.bankBranches.root,
            icon: ICONS.bank
          },
          {
            title: t('locations'),
            path: paths.dashboard.locations.root,
            icon: ICONS.location,
            children: [
              { title: t('provinces'), path: paths.dashboard.locations.root },
              { title: t('districts'), path: paths.dashboard.locations.districts.root },
              { title: t('communes'), path: paths.dashboard.locations.communes.root },
              // { title: t('villages'), path: paths.dashboard.locations.villages },
            ],
          },
          {
            title: t('contents'),
            path: paths.dashboard.website.root,
            icon: ICONS.contentSetting,
            children: [
              { title: t('posts'), path: paths.dashboard.website.root },
              { title: t('bankBlogs'), path: paths.dashboard.website.blogs.root },
              { title: t('pages'), path: paths.dashboard.website.pages },
              { title: t('categories'), path: paths.dashboard.website.categories },
              { title: t('blocks'), path: paths.dashboard.website.blocks },
              { title: t('sliders'), path: paths.dashboard.website.sliders },
            ],
          },
        ],
      },
      {
        subheader: t('settings'),
        items: [
          {
            title: t('requested'),
            path: paths.dashboard.settings.requested.root,
            icon: ICONS.requestedSetting,
            children: [
              { title: t('requestedTypes'), path: paths.dashboard.settings.requested.root },
              { title: t('requestedStatuses'), path: paths.dashboard.settings.requested.statuses },
              { title: t('requestedTasks'), path: paths.dashboard.settings.requested.tasks },
            ],
          },
          {
            title: t('property'),
            path: paths.dashboard.settings.property.root,
            icon: ICONS.propertySetting,
            children: [
              { title: t('banks'), path: paths.dashboard.settings.property.root },
              { title: t('propertyTypes'), path: paths.dashboard.settings.property.types.root },
              { title: t('propertyPurposes'), path: paths.dashboard.settings.property.purposes.root },
              { title: t('landTitles'), path: paths.dashboard.settings.property.landTitles.root },
              { title: t('mainFeatures'), path: paths.dashboard.settings.property.main.root },
              { title: t('propertyFeatures'), path: paths.dashboard.settings.property.features.root },
            ],
          },
          {
            title: t('loans'),
            path: paths.dashboard.settings.loans.root,
            icon: ICONS.loan,
            children: [
              { title: t('loanTypes'), path: paths.dashboard.settings.loans.root },
              { title: t('loanStatuses'), path: paths.dashboard.settings.loans.statuses },
            ],
          },
          {
            title: t('people'),
            path: paths.dashboard.settings.people.root,
            icon: ICONS.userSetting,
            children: [
              { title: t('peopleTypes'), path: paths.dashboard.settings.people.root },
              { title: t('departments'), path: paths.dashboard.settings.people.departments },
              { title: t('positions'), path: paths.dashboard.settings.people.positions },
              { title: t('roles'), path: paths.dashboard.settings.people.roles },
            ],
          },
          {
            title: t('wallets'),
            path: paths.dashboard.settings.wallets.root,
            icon: ICONS.userSetting,
            children: [
              { title: t('currencies'), path: paths.dashboard.settings.wallets.root },
              { title: t('merchants'), path: paths.dashboard.settings.wallets.merchants.root },
              { title: t('merchantTypes'), path: paths.dashboard.settings.wallets.types.root },
              { title: t('categories'), path: paths.dashboard.settings.wallets.categories.root },
            ],
          },
          {
            title: t('website'),
            path: paths.dashboard.settings.website.root,
            icon: ICONS.webSetting,
            children: [
              { title: t('settings'), path: paths.dashboard.settings.website.root },
              { title: t('menus'), path: paths.dashboard.settings.website.menu },
            ],
          },
        ],
      },
    ],
    [t]
  );

  return data;
}
